<template>
  <!--begin::Header-->
  <div id="kt_header" style="" class="header align-items-stretch">
    <!--begin::Container-->
    <div
      :class="{
        'container-fluid': headerWidthFluid,
        'container-xxl': !headerWidthFluid,
      }"
      class="d-flex align-items-stretch justify-content-between"
    >
      <!--begin::Title-->
        <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3 d-block d-md-none">
          {{ title ? $t(title) : "" }}
        </h1>


        <!--begin::Navs-->
        <div class="d-flex overflow-auto h-55px d-none d-md-block mt-4">
          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-sembolder flex-nowrap fs-lg-5">
            <li class="me-6 nav-item" v-for="(item, index) in state.MainMenuConfig" :key="index">
              <router-link  :to="item.path" class="nav-link text-active-primary me-6" active-class="active" >
                <i class="mt-0 fs-5 me-4  fs-lg-4" :class="item.icon"></i>
                {{item.title}}
              </router-link>
            </li>
          </ul>
        </div>
      <!--begin::Navs-->


      <!--end::Title-->

      <!--begin::Wrapper-->
      <div
        class="
          d-flex
          align-items-stretch
          justify-content-between
          flex-lg-grow-1
        "
      >
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_menu_nav">
          <!--begin::Page title-->
          <div
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="
              page-title
              d-flex
              align-items-center
              flex-wrap
              me-3
              mb-5 mb-lg-0
            "
          >
            <a
              class="btn btn-light me-3"
              v-if="displayBack()"
              ><i class="fas fa-arrow-left fs-4 me-2"></i> {{ $t("Retour") }}</a
            >
          </div>
          <!--end::Page title-->
        </div>
        <!--end::Navbar-->

        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <KTTopbar @reloadRouter="reloadRouter($event)"></KTTopbar>
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import KTTopbar from "@/layout/header/Topbar.vue";
import { useRouter, useRoute } from "vue-router";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const breadcrumbs = store.getters.pageBreadcrumbPath;

    const state = reactive({
      MainMenuConfig: [],
    });

    // localStorage.setItem('storedData', this.input)

    onMounted(async () => {
      let menu = await mAxiosApi.prototype.getAxios("/getMenu");
      menu.results.forEach(element => {
        element.childs.forEach(child => {
          menu.results.push(child);
        });
      });
      state.MainMenuConfig = menu.results;

      state.MainMenuConfig = state.MainMenuConfig.filter(
        (item: any) =>item.desktop == "1"
      );

    });

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const displayBack = () => {
      const mPath = route.path as any;
      if (mPath.indexOf("/commande/") === 0) return true;
      return false;
    };

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      breadcrumbs,
      displayBack,
      reloadRouter,
      state
    };
  },
});

</script>
