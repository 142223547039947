
import { defineComponent, computed, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
// import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
import KTDrawerMessenger from "@/layout/extras/DrawerMessenger.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { CurvedBottomNavigation } from "bottom-navigation-vue";

import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTToolbar,
    KTScrollTop,
    KTCreateApp,
    KTUserMenu,
    KTDrawerMessenger,
    KTLoader,
    CurvedBottomNavigation
  },
  setup() {
    
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      loadRouter : 0,
      selected: Number,
      options: [] as any,
      initOptions: [] as any,
    });

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const reloadRouter = () => {
      state.loadRouter += 1;
    }

    const getBackgroundStyle = () => {
      return window.matchMedia('(min-width: 768px)').matches && 'background: url(media/still/tasse-cafe-clavier-sans-fil-souris.jpg) no-repeat center center fixed; background-size: cover;'
    }

    onMounted(async () => {

      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "sign-in" });
      }
      const menu = await mAxiosApi.prototype.getAxios("/getMenu");
      state.options = menu.results;
      state.initOptions = menu.results;
      

      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "sign-in" });
      }
      
      state.selected = menu.results.find(el => route.path.indexOf(el.path) > -1)?.id;

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);

      if (store.getters.isUserAuthenticated &&
      !localStorage.getItem("publicKey") &&
      (/iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      ) 
      {
        const userInfos = await mAxiosApi.prototype.getAxios("/getUserInfos");
        const userName = userInfos.results.emp_nomprenom;
        const userEmail = userInfos.results.emp_mail_pro;
        let challenge = new Uint8Array(64);
        crypto.getRandomValues(challenge);
        challenge =  Uint8Array.from(challenge + '', c => c.charCodeAt(0));
        const randomIdUser = new Uint8Array(16);
        crypto.getRandomValues(challenge);

        const publicKey = {
          challenge: challenge,
          rp: {
            id: process.env.VUE_APP_CURRENT_URL,
            name: "StillRH"
          },
          user: {
            id: Uint8Array.from(randomIdUser + '', c => c.charCodeAt(0)),
            name: userEmail,
            displayName: userName
          },
          // authenticatorSelection: {
          //   requireResidentKey: true,
          //   residentKey: true,
          // },
          pubKeyCredParams: [ {type: "public-key", alg: -7}],
          timeout: 60000,
          attestation: "direct",
        } as any;
        
        const credential = await navigator.credentials.create({publicKey}) as any;
        const credId = credential.rawId;
        const publicKeyString = String.fromCharCode.apply(null, new Uint8Array(credId) as any);
        localStorage.setItem('publicKey', publicKeyString);
      }
    });

    watch(
      () => route.path,
      () => {
        state.selected = state.initOptions.find(el => route.path.indexOf(el.path) > -1)?.id;
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: "sign-in" });
        }

        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      reloadRouter,
      state,
      getBackgroundStyle
    };
  },
});
