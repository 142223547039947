
import { defineComponent, watch, reactive, onMounted } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "topbar",
  emits: ["reloadRouter"],
  components: {
    KTUserMenu
  },
  setup(props, { emit }) {
    const store = useStore();
    // let dropdown1 = ref(null) as any

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const router = useRouter();
    const state = reactive({
      initialContact: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
      allUsers: [] as any,
      perm: false,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;

      state.initialContact =
        (state.contact.con_prenom ? state.contact.con_prenom.charAt(0) : "") +
        (state.contact.con_nom ? state.contact.con_nom.charAt(0) : "");
    }

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    const getEnv = () => {
      if (process.env.VUE_APP_ENVIRONNEMENT == "LOCAL" || process.env.VUE_APP_ENVIRONNEMENT == "RECETTE") {
        return "dev";
      } 
    }

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    // const showLogout = () => {
    //   console.log(dropdown1.value)
    //   if (!dropdown1.value) return
    //     (dropdown1.value).visible() = true as any
    // }

    const changeMail = (e) => {
      router.push({ name: "loader", params: {otherparam: e.target.value} });
    }

    onMounted(async() => {
      const allUsers = await mAxiosApi.prototype.getAxios("/getAllUsers");
      state.allUsers = allUsers.results;
      const perm = await mAxiosApi.prototype.getAxios("/getFolderPerm") 
      state.perm = perm.results
    })

    return {
      state,
      getEnv,
      reloadRouter,
      changeMail,
      // showLogout,
      // dropdown1
    };
  },
});
