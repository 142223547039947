
import { defineComponent, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import KTTopbar from "@/layout/header/Topbar.vue";
import { useRouter, useRoute } from "vue-router";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const breadcrumbs = store.getters.pageBreadcrumbPath;

    const state = reactive({
      MainMenuConfig: [],
    });

    // localStorage.setItem('storedData', this.input)

    onMounted(async () => {
      let menu = await mAxiosApi.prototype.getAxios("/getMenu");
      menu.results.forEach(element => {
        element.childs.forEach(child => {
          menu.results.push(child);
        });
      });
      state.MainMenuConfig = menu.results;

      state.MainMenuConfig = state.MainMenuConfig.filter(
        (item: any) =>item.desktop == "1"
      );

    });

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const displayBack = () => {
      const mPath = route.path as any;
      if (mPath.indexOf("/commande/") === 0) return true;
      return false;
    };

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      breadcrumbs,
      displayBack,
      reloadRouter,
      state
    };
  },
});

