
import { defineComponent } from "vue";
import * as moment from "moment";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    header: String,
    files: Object as any,
  },
  setup() {
    //moment.default.locale("fr");

    const downloadFile = async (item) => {
      
      let myList = await mAxiosApi.prototype.getAxios("/getFileChat/" + item.seq + '/' + item.name);
      const data = encodeURI(myList.results[0].fil_blob);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", item.name);
      link.click();

    };

    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD hh:mm:ss").fromNow();
    };

    const lengthChar = (str) => {
      return str.length;
    };

    return {
      formatDateAgo,
      lengthChar,
      downloadFile,
    };
  },
});

